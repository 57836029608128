import React from 'react';
import './AboutUs.css';
import { motion } from 'framer-motion';

const AboutUs = () => {
    return (
        <section className="au-wrapper">
            <div className="paddings innerWidth flexCenter au-container">
                <div className="flexCenter au-left">
                    <motion.div
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="image-container">
                        <img src="./abous_us.png" alt="aboutus" />
                    </motion.div>
                </div>
                <div className="au-wrapper primaryText centerText">
                    <h1>
                        <motion.h1
                            initial={{ y: "-2rem", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                duration: 2,
                                type: "spring"
                            }}>
                            O Nas
                        </motion.h1>
                    </h1>
                    <div>
                        <motion.div
                            initial={{ y: "2rem", opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{
                                duration: 2,
                                type: "spring"
                            }}
                            className="sd-description secondaryText">
                            Dobrodošli v Larenova, vašem zanesljivem partnerju za zaključna gradbena dela. Smo strastna ekipa gradbenih strokovnjakov, ki združujejo ljubezen do estetsko dovršenih prostorov s predanostjo kakovosti. <br />
                            Naša zgodba temelji na visokih standardih, inovativnih rešitvah ter sodelovanju z vami od začetka do konca projekta. Verjamemo v transparentnost, odprt dialog in prilagajanje vašim potrebam. <br />
                            Sodelujte z nami, da skupaj ustvarimo prostor, ki odraža vašo vizijo in izpolnjuje najvišje standarde kakovosti.
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs