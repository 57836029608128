import React from 'react'
import data from '../../assets/gallery.json'
import './Gallery.css'
import { motion } from 'framer-motion'

const Gallery = () => {
    const row1 = data.slice(0, 3);
    const row2 = data.slice(3, 6);
    const row3 = data.slice(6, 9);
    const row4 = data.slice(9, 12);
    const row5 = data.slice(12, 15);
    const row6 = data.slice(15, 18);
    const row7 = data.slice(18, 21);
    const row8 = data.slice(21, 24);
    const row9 = data.slice(24, 27);
    const row10 = data.slice(27, 30);
    const row11 = data.slice(30, 33);
    const row12 = data.slice(33, 36);
    const row13 = data.slice(36, 39);
    const row14 = data.slice(39, 42);
    

    return (
        <section className="g-wrapper">
            <div className="textpaddings innerWidth g-container">
                <motion.div
                    initial={{ y: "-4rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                >
                    <h1 className="primaryText g-tittle-padding">Galerija</h1>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row1.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row2.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row3.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row4.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row5.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row6.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row7.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row8.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row9.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row10.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row11.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row12.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row13.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="g-image-container">
                    {
                        row14.map((image, i) => (
                            <div key={i} className="g-image-padding">
                                <img src={image.image} alt="Larenova Image" style={{ width: '400px', height: '400px' }} />
                            </div>
                        ))
                    }
                </motion.div>
            </div>
        </section>
    )
}

export default Gallery