import React, { useEffect, useState } from 'react';
import './ServiceDetail.css';
import data from '../../assets/slider.json'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom';

const ServiceDetail = () => {

  const { serviceId } = useParams();
  const serviceItem = data.find(item => item.id === parseInt(serviceId, 10));
  const [currentPageId, setCurrentPageId] = useState(serviceId);
  
  useEffect(() => {
    setCurrentPageId(serviceId);
  }, [serviceId])
  
  if(!serviceItem) {
    return (
      <div>
        Item not found for id: {serviceId}
      </div>
    );
  }

  return (
    <section className="sd-wrapper">
      <div className="paddings innerWidth flexCenter sd-container">
        <div className="flexCenter sd-left">
          <motion.div 
          key={currentPageId}
          initial={{x: "-7rem", opacity: 0}}
          animate={{x: 0, opacity: 1}}
          transition={{
            duration: 2,
            type: "spring"
          }}
          className="image-container">
            <img src={serviceItem.image} alt="image" />
          </motion.div>
        </div>
        <div className='sd-wrapper primaryText'>
          <div>
            <motion.h1 
              key={currentPageId}
              initial={{y: "-2rem", opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition = {{
                duration: 2,
                type: "spring"
              }}>
              {serviceItem.name}
            </motion.h1>
          </div>
          <div>
            <motion.div 
            key={currentPageId}
            initial={{y: "2rem", opacity:0}}
            animate={{y: 0, opacity: 1}}
            transition = {{
              duration: 2,
              type: "spring"
            }}
            className="sd-description secondaryText">
              {serviceItem.description}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceDetail