import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
import { BiMenuAltRight } from 'react-icons/bi';
import OutsideClickHandler from 'react-outside-click-handler';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false)
  
  const getMenuStyles = (menuOpened) => {
    if(document.documentElement.clientWidth <= 800) {
      return{right: !menuOpened && "-100%"}
    }
  }

  return (
    <section className="h-wrapper">
        <div className="flexCenter paddings innerWidth h-container">
          <Link to="/">
            <img src="/larenova-logo.png" alt="logo" width={250} />
          </Link>

          <OutsideClickHandler
          onOutsideClick={() => {
            setMenuOpened(false)
          }}>
            <div className="flexCenter h-menu" style={getMenuStyles(menuOpened)}>
              <Link to="/" className="nav-link">Domov</Link>
                <div className="dropdown">
                  <a href="" className="dropbtn nav-link">Storitve</a>
                  <div className="dropdown-content">
                    <Link to="/services/1">Suhomontažna dela</Link>
                    <Link to="/services/2">Slikopleskarska dela</Link>
                    <Link to="/services/3">Kitanje in glajenje površin</Link>
                    <Link to="/services/4">Keramičarska dela</Link>
                    <Link to="/services/5">Polaganje talnih oblog</Link>
                    <Link to="/services/6">Fina gradbena dela</Link>
                  </div>
                </div>
                <Link to="/gallery" className="nav-link">Galerija</Link>
                <Link to="/inquire" className="nav-link">Pošlji Povpraševanje</Link>
                <Link to="/aboutUs" className="nav-link">O nas</Link>
                <button className="button">
                  <Link to="/contact">Kontakt</Link>
                </button>
            </div>
          </OutsideClickHandler>
          <div className="menu-icon" onClick = {() => setMenuOpened((prev) => !prev)}>
              <BiMenuAltRight size={30}/>
          </div>
        </div>

    </section>
  )
}

export default Header