import React from 'react'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import "swiper/css"
import './Services.css'
import data from '../../assets/slider.json'
import { sliderSettings } from '../../utils/common';
import { Link } from 'react-router-dom';

const Services = () => {
  return (
    <section className="s-wrapper">
        <div className="paddings innerWidth s-container">
            <div className="s-head flexColStart">
                <span className="primaryText">Storitve</span>
            </div>
            <Swiper {...sliderSettings}>
                <SliderButtons/>
                {
                    data.map((card, i) => (
                        <SwiperSlide key={i}>
                            <div className="flexColStart s-card">
                                <div className="s-image-container">
                                    <Link to={`/services/${i+1}`}>
                                        <img src={card.image} alt="home" />
                                    </Link>
                                </div>
                                <span className="primaryText">{card.name}</span>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    </section>
  )
}

export default Services

const SliderButtons = () => {
    const swiper = useSwiper();
    return (
        <div className="flexCenter s-button">
            <button onClick={() => swiper.slidePrev()}>&lt;</button>
            <button onClick={() => swiper.slideNext()}>&gt;</button>
        </div>
    )
}