import React, { useState } from 'react'
import './Inquire.css'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';

const Inquire = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [description, setDescription] = useState('')
    const [kvadratura, setKvadratura] = useState('')
    const [selectedOptions, setSelectedOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        try {
            const response = await fetch('http://localhost:3001/submitForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, number, description, kvadratura, selectedOptions })
            })

            if (response.ok) {
                navigate('/inquireSuccess')
            }
        } finally {
            setLoading(false)
        }
    }


    const handleCheckboxChange = (value) => {
        if (selectedOptions.includes(value)) {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        } else {
            setSelectedOptions([...selectedOptions, value]);
        }
    };

    return (
        <section className="i-wrapper">
            <div className="flexCenter paddings innerWidth i-container">
                <motion.h1
                    initial={{ y: "-4rem", opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        duration: 2,
                        type: "spring"
                    }}
                    className="primaryText">
                    Pošljite povpraševanje
                </motion.h1>
                <form onSubmit={handleSubmit} className="inquire-form">
                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                    >
                        <input className="input-field" type="text" value={name} placeholder="Vpišite svoje ime" onChange={(e) => setName(e.target.value)} />
                    </motion.label>

                    <motion.label
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                    >
                        <input className="input-field" type="email" value={email} placeholder="Vaš Email" onChange={(e) => setEmail(e.target.value)} />
                    </motion.label>

                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                    >
                        <input className="input-field" type="text" value={number} placeholder="Telefonska št." onChange={(e) => setNumber(e.target.value)} />
                    </motion.label>

                    <motion.h1
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="i-h1">Kakšne storitve potrebujete?</motion.h1>

                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Suhomontažna dela" checked={selectedOptions.includes('Suhomontažna dela')} onChange={() => handleCheckboxChange('Suhomontažna dela')} /><span className="i-span">Suhomontažna dela</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Slikopleskarske storitve" checked={selectedOptions.includes('Slikopleskarske storitve')} onChange={() => handleCheckboxChange('Slikopleskarske storitve')} /><span className="i-span">Slikopleskarska dela</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Kitanje in glajenje površin" checked={selectedOptions.includes('Kitanje in glajenje površin')} onChange={() => handleCheckboxChange('Kitanje in glajenje površin')} /><span className="i-span">Kitanje in glajenje površin</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Keramičarska dela" checked={selectedOptions.includes('Keramičarska dela')} onChange={() => handleCheckboxChange('Keramičarska dela')} /><span className="i-span">Keramičarska dela</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Polaganje talnih oblog" checked={selectedOptions.includes('Polaganje talnih oblog')} onChange={() => handleCheckboxChange('Polaganje talnih oblog')} /><span className="i-span">Polaganje talnih oblog</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                        className="checkbox-label">
                        <input className="checkbox-field" type="checkbox" value="Fina gradbena dela" checked={selectedOptions.includes('Fina gradbena dela')} onChange={() => handleCheckboxChange('Fina gradbena dela')} /><span className="i-span">Fina gradbena dela</span>
                    </motion.label>

                    <motion.label
                        initial={{ x: "-7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}>
                        <input className="input-field" type="text" value={kvadratura} placeholder="Kvadratura delovne površine m2" onChange={(e) => setKvadratura(e.target.value)}></input>
                    </motion.label>

                    <motion.label
                        initial={{ x: "7rem", opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                    >
                        <textarea className="input-field" type="text" value={description} placeholder="Opis" onChange={(e) => setDescription(e.target.value)} />
                    </motion.label>

                    <motion.div
                        initial={{ y: "4rem", opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            duration: 2,
                            type: "spring"
                        }}
                    >
                        <div>
                            {loading && <p>Pošiljam...</p>}
                        </div>
                        { !loading && <button type="submit" className="button">
                            Pošlji
                        </button>}

                    </motion.div>

                </form>
            </div>
        </section>
    )
}

export default Inquire