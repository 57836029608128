import React from 'react'
import './Ratings.css'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import "swiper/css"
import { sliderSettings } from '../../utils/common';
import data from '../../assets/ratings.json'

const Ratings = () => {

  return (
    <section className="r-wrapperr">
      <div className="paddings innerWidth r-container">
        <div className="r-head flexColStart">
          <span className="orangeText">Mnenja Strank</span>
        </div>

        <Swiper {...sliderSettings}>
          <SliderButtons/>
          {
            data.map((card, i) => (
              <SwiperSlide key={i}>
                <div className="flexColStart r-card">
                  <div className="r-image-container">
                    <img src={card.image} alt="user" />
                  </div>

                  <span className="authorText">{card.author}</span>
                  <span className="primaryText smallerFont">{card.name}</span>
                  <span className="secondaryText">{card.description}</span>

                </div>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  )
}

export default Ratings

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
      <div className="flexCenter s-button">
          <button onClick={() => swiper.slidePrev()}>&lt;</button>
          <button onClick={() => swiper.slideNext()}>&gt;</button>
      </div>
  )
}