import React from 'react'
import './Contact.css'
import { MdCall } from 'react-icons/md'
import { BsFillChatDotsFill } from 'react-icons/bs'
import { HiChatBubbleBottomCenter } from 'react-icons/hi2'
import { motion } from 'framer-motion';

const Contact = () => {
  return (
    <section className="c-wrapper">
        <div className="paddings innerWidth flexCenter c-container">
            {/* left side */}
            <div className="flexColStart c-left">
                <motion.div 
                initial={{x: "-4rem", opacity: 0}}
                animate={{x: 0, opacity: 1}}
                transition = {{
                    duration: 2,
                    type: "spring"
                }}
                className="flexColStart c-left">
                    <span className="orangeText">Kontakt</span>
                    <span className="primaryText">Enostavno nas kontaktirajte</span>
                    <span className="secondaryText">Vedno smo pripravljeni pomagati z zagotavljanjem najboljše storitve.</span>
                </motion.div>
                <motion.div 
                initial={{y: "3rem", opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition = {{
                    duration: 2,
                    type: "spring"
                }}
                className="flexColStart contactModes">
                    <div className="flexStart row">
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <MdCall size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className="primaryText">Call</span>
                                    <span className="secondaryText">+386 31 846 079</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Call Now</div>
                        </div>

                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className="primaryText">Chat</span>
                                    <span className="secondaryText">+386 31 846 079</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Chat Now</div>
                        </div>
                    </div>

                    <div className="flexStart row">
                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <BsFillChatDotsFill size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className="primaryText">Video Call</span>
                                    <span className="secondaryText">+386 31 846 079</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Video Call Now</div>
                        </div>

                        <div className="flexColCenter mode">
                            <div className="flexStart">
                                <div className="flexCenter icon">
                                    <HiChatBubbleBottomCenter size={25}/>
                                </div>
                                <div className="flexColStart detail">
                                    <span className="primaryText">Message</span>
                                    <span className="secondaryText">+386 31 846 079</span>
                                </div>
                            </div>
                            <div className="flexCenter button">Message Now</div>
                        </div>
                    </div>
                </motion.div>

            </div>

            {/* right side */}
            <div className="c-right">
                <motion.div 
                initial={{x: "2rem", opacity: 0}}
                animate={{x:0, opacity: 1}}
                transition = {{
                    duration: 2,
                    type: "spring"
                }}
                className="image-container">
                    <img src="./contact_us.jpeg" alt="" />
                </motion.div>
            </div>
        </div>
    </section>
  )
}

export default Contact