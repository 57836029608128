import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import "react-accessible-accordion/dist/fancy-example.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import './Value.css';
import data from '../../utils/accordion';

const Value = () => {
  const [className, setClassName] = useState("collapsed");

  const handleAccordionToggle = (isExpanded) => {
    setClassName(isExpanded ? "expanded" : "collapsed");
  };

  return (
    <section className="v-wrapper">
      <div className="paddings innerWidth flexCenter v-container">
        {/* left side */}
        <div className="v-left">
          <div className="image-container">
            <img src="./hero2.jpg" alt="" />
          </div>
        </div>

        {/* right side */}
        <div className="flexColStart v-right">
          <span className="orangeText">Naša Vrednota</span>
          <span className="primaryText">Vrednost, ki jo nudimo vam</span>
          <span className="secondaryText">
            Vedno smo pripravljeni pomagati, zagotavljamo vam najboljše storitve.
            <br />
            Verjamemo, da dobro bivališče lahko izboljša vaše življenje.
          </span>

          <Accordion
            className={`accordion ${className}`}
            allowMultipleExpanded={false}
            preExpanded={[0]}
          >
            {data.map((item, i) => (
              <AccordionItem className="accordionItem" key={i} uuid={i}>
                <AccordionItemHeading>
                  <AccordionItemButton
                    className="flexCenter accordionButton"
                    onClick={() => handleAccordionToggle()}
                  >
                    <div className="flexCenter icon">{item.icon}</div>
                    <span className="primaryText">{item.heading}</span>
                    <div className="flexCenter icon"><MdOutlineArrowDropDown size={20} /></div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="secondaryText">{item.detail}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default Value;
