import React from 'react'
import './Footer.css'
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <section className="f-wrapper">
        <div className="paddings innerWidth flexCenter f-container">

            <div className="flexColStart f-left">
                <img src="/larenova-logo.png" alt="logo" width={170}/>
                <span className="secondaryText">
                Naša vizija je polepšati vaš dom, <br/>
                da se v njem počutite prečudovito.
                </span>
            </div>

            <div className="flexColStart f-right">
                <span className="primaryText">Informacije</span>
                <span className="secondaryText"><MdEmail /> info@larenova.si</span>
                <span className="secondaryText"><FaLocationDot /> Trzin 1236, Jemčeva cesta 23</span>
                <span className="secondaryText"><FaPhoneAlt /> +386 31 846 079</span>
            </div> 
        </div>
    </section>
  )
}

export default Footer