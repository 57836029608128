import React from 'react'
import './Hero.css'
import CountUp from 'react-countup';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <section className="hero-wrapper">
      <div className="paddings innerWidth flexCenter hero-container">
        <div className="flexColStart hero-left">
          <div className="hero-title">
            
            <motion.h1 
            initial={{y: "2rem", opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition = {{
              duration: 2,
              type: "spring"
            }}>
              Zaključna<br />
              Gradbena<br />
              Dela
            </motion.h1>
          </div>
          <div className="flexColStart hero-description">
            <span>Raziščite široko paleto gradbenih rešitev z lahkoto pri Larenova.<br /> 
            S svojo kakovostno storitvijo prenove stanovanjskih objektov <br/>ponujamo možnost izboljšanja kakovosti bivanja, zmanjšanja<br/> stroškov vzdrževanja in povečanja vrednosti nepremičnine. </span>
            <span>Dovolite nam, da skupaj oblikujmo vašo prihodnost.</span>
          </div>

          <div className="flexCenter stats">
            <div className="flexColCenter stat">
              <span><CountUp start={180} end={300} duration={4} /><span>+</span></span>
              <span className="secondaryText">Opravljena Dela</span>
            </div>

            <div className="flexColCenter stat">
              <span><CountUp start={120} end={300} duration={4} /><span>+</span></span>
              <span className="secondaryText">Zadovoljne  Stranke</span>
            </div>

            <div className="flexColCenter stat">
              <span><CountUp start={150} end={300} duration={6}/><span>+</span></span>
              <span className="secondaryText">Zaključenih projektov</span>
            </div>
          </div>
        </div>
        {/* right side */}
        <div className="flexCenter hero-right">
          <motion.div 
          initial={{x: "7rem", opacity: 0}}
          animate={{x: 0, opacity: 1}}
          transition={{
            duration: 2,
            type: "spring"
          }}
          className="image-container">
            <img src="./hero-image.png" alt="hero" />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Hero