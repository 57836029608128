import { HiShieldCheck } from "react-icons/hi";
import { MdAssuredWorkload , MdAssignment  } from "react-icons/md";
const data = [
  {
    icon: <HiShieldCheck/>,
    heading: "Usposobljeno osebje",
    detail:
      "Naše izkušnje zagotavljajo vrhunski končni izdelek. Smo resen in odgovoren partner, ki se drži dogovorov. ",
  },
  {
    icon: <MdAssuredWorkload  />,
    heading: "Renovacija ali osvežitev, vaša izbira",
    detail:
      "Opravimo: slikopleskarska dela, izdelava dekorativnih tehnik,suhomontažni sistemi,.. izpolnimo tudi vaše skrite želje s pomočjo naših poslovnih partnerjev.",
  },
  {
    icon: <MdAssignment />,
    heading: "Prilagojene rešitve za vaš dom",
    detail:
      "Odkrijte prilagojene rešitve za vaš dom oblikovane glede na vaše edinstvene potrebe in vaše želje. Vaši prostori so naše neporisano platno.",
  },
];
export default data;