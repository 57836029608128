import React from 'react'

const InquireSent = () => {
  return (
    <section className="is-wrapper">
        <div className="flexCenter paddings innerWidth is-container">
            <h1 className="primaryText">Povpraševanje uspešno poslano!</h1>
        </div>
    </section>
  )
}

export default InquireSent