import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import ServiceDetail from './components/ServiceDetail/ServiceDetail';
import Value from './components/Value/Value';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Inquire from './components/Inquire/Inquire';
import InquireSent from './components/InquireSent/InquireSent';
import AboutUs from './components/AboutUs/AboutUs';
import Ratings from './components/Ratings/Ratings';
import Gallery from './components/Gallery/Gallery';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Header />
              <Hero />
              <Services />
              <Value />
              <Ratings />
              <Footer />
            </>
          } />

          <Route path="/services/:serviceId" element={
            <>
              <Header />
              <ServiceDetail />
              <Footer />
            </>
          } />

          <Route path="/contact" element={
            <>
              <Header />
              <Contact />
              <Footer />
            </>
          } />

          <Route path="/inquire" element={
            <>
              <Header />
              <Inquire />
              <Footer />
            </>
          } />

          <Route path="/inquireSuccess" element={
            <>
              <Header />
              <InquireSent />
              <Footer />
            </>
          } />

          <Route path="/aboutUs" element={
            <>
              <Header />
              <AboutUs />
              <Footer />
            </>
          } />

          <Route path="/gallery" element={
            <>
              <Header />
              <Gallery />
              <Footer />
            </>
          } />

          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
